import Container from 'react-bootstrap/Container';
export default function Dmca()
{

return(
    <Container fluid='md' className='my-3 bg-light'>
<div>

<h2>DMCA</h2>
<p>If we Have added some content that belong to you or your organization by mistake, We are sorry for that. We apologize for that and assure you that this wont be repeated in future. If you are rightful owner of the content used in our Website, Please mail us with your Name, Organization Name, Contact Details, Copyright infringing URL and Copyright Proof (URL or Legal Document) at <strong>allstoresrice@gmail.com</strong></p>
<p>I assure you that, I will remove the infringing content as earliest.</p>

    
</div>
</Container>
)

}