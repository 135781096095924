import Header from './Header'
import Holding from './Holding';
import StoreImg from './StoreImg';
import SearchBox from './SearchBox';
import { NavLink,useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import AddC from './AddC';
import ShopBaner from './ShopBaner';
import FooterAdd from './FooterAdd';
import Container from 'react-bootstrap/Container';
import React, { useState } from 'react';
import HomeBanner from './HomeBanner';
import Navbar from './NavBar';
import BannerAds from './BannerAds';
import SmallCardGroup from './SmallCardgroup';
import SmallCardAds from './SmallCardAds'
import Geolocation from './Geolocation';
import DownProduct from './DownProduct'
import BannerAds_1 from './BannerAds_1';
import DownProject_1 from './DownProduct_1';
import DownProject_2 from './DownProject_2';
import Downproject_3 from './DownProject_3';
import ProductNearByArea from './ProductNearByArea';
import PosterNearByArea from './PosterNearByArea';


//window.siteUrl="http://localhost:4500";
window.siteUrl="//allstoresprice.com:3443";

export default function HomePage(props)
{
const [getloginusercity, setloginusercity]=useState('')
const [getfulldata, setfulldata]=useState([])
const [getneardata, setneardata]=useState([])
const [getposter, setposter]=useState([])
    const[isKeyUpdated, getkey]=useState()

    useEffect (()=>{

        if(localStorage.getItem('user-info'))
  {
    const loc=localStorage.getItem('user-info')
    const parseVal=JSON.parse(loc)
    
    setloginusercity(parseVal.City)
  }
})
   // console.log("isKeyUpdated",isKeyUpdated)
    return(
        //style={{backgroundColor: "#f1f2eb"}}
<Container fluid className='my-1'  >
    <div style={{backgroundColor: "#20c997",position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 1000
  }}>
    <SearchBox inputKey={getkey} />
<Header />


</div>
<div style={{ height: '110px' }}></div>
{/* <HomeBanner/> */}

{/* <Geolocation/> */}
<BannerAds fulldata={getfulldata} />
<SmallCardAds city={getloginusercity} fulldata={getfulldata}/>
<SmallCardGroup fulldata={getfulldata}/>
<BannerAds_1 fulldata={getfulldata}/>
{/* {console.log("getbanner",getbanner)} */}
{/* <BannerAds_1 /> */}
{/* <AddC/> */}
<div style={{backgroundColor: "#f5fff9"}}>
      
      
      <StoreImg city={getloginusercity}/>
      </div>
      <Holding ProData={isKeyUpdated} fulldata={setfulldata} />
      {/* <DownProduct fulldata={getfulldata}/> */}
      <DownProject_1 fulldata={getfulldata}/>
      <DownProject_2 fulldata={getfulldata}/>
      <Downproject_3 fulldata={getfulldata}/>
      <ProductNearByArea ProData={isKeyUpdated} neardata={setneardata}/>
      <PosterNearByArea fulldata={getneardata}/>

      <Navbar/>
      {/* <FooterAdd/> */}
</Container>

    )
}