import logo from './logo.svg';
import './App.css';
import Login from './LoginAuth/Login';

import SignUp from './LoginAuth/SignUp';
import PropsFunctionCall from './UserShop';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Resetpassword from './LoginAuth/Reset_password';
import MobileOtp from './LoginAuth/MobileOtp';
import Header from './Header'
import Holding from './Holding';
import StoreImg from './StoreImg';
import SearchBox from './SearchBox';
import HomePage from './HomePage';
import ShopHomePage from './ShopHomePage';
import AddProduct from './AddProduct';
import Profile from './Profile';
import Usershop from './UserShop';
import OrderProduct from './OrderProduct';

import { Helmet } from 'react-helmet';
import SelectPlan from './SelectPlan';
import Dmca from './DMCA';
import Privacypolicy from './Privacypolicy';
import Contactus from './ContactUS';
import Aboutus from './AboutUS';
import Protected from './Protected';
import Feedback from './Feedback';
import ProductDetails from './ProductDetails';




function App() {
  return (
    <div className="App">
<Helmet>
      <meta charSet="utf-8" />
      <link rel="canonical" href="https://allstoresprice.com" />
      <title>Get Your Local Product Online with allstoresprice.com</title>
      <meta
    name="description"
    content="This will help you to get your product, homemade product and shop online."
  />
  <meta name="keywords" content="This will help you to get your product, homemade product and shop online."/> 
      </Helmet>

      <BrowserRouter>
      <Routes>
      <Route path="/myshop" element={<Usershop/>}/>
      {/* <Route path="/profile"element={<Profile/>}/> */}
      <Route path="/profile" element={<Protected Cmp={Profile} />}/>
      {/* <Route path="/order"element={<OrderProduct/>}/>  */}
        
      {/* <Route path="/order" element={<Protected Cmp={OrderProduct} />}/> */}
      
      <Route path="/:name/"element={<ShopHomePage/>}>
        <Route path=":product_id" element={<ProductDetails/>}/>
      </Route>
      <Route path="/"element={<HomePage/>}/>
      <Route path="/signup"element={<SignUp/>}/>
      <Route path="/login"element={<Login/>}/>
      
      <Route path="/setpassword"element={<Resetpassword/>}/>
      <Route path="/otp"element={<MobileOtp/>}/>
       {/* <Route path="/selectplan"element={<SelectPlan/>}/>  */}
      <Route path="/aboutus" element={<Aboutus/>}/>
      <Route path="/contactus" element={<Contactus/>}/>
      <Route path="/privacypolicy" element={<Privacypolicy/>}/>
      <Route path="/dmca" element={<Dmca/>}/>
      <Route path='*' element={<h1>404 Page Not Found</h1>}/>
      <Route path="/feedback" element={<Feedback/>}/>
      </Routes>
     
      </BrowserRouter>
    </div>
  );
}

export default App;
