import { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { PiShoppingCartThin } from "react-icons/pi";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import {useParams,useNavigate } from "react-router-dom";
import Toastnot from './Toastnot';
import { FiEdit2 } from "react-icons/fi";
import InputGroup from 'react-bootstrap/InputGroup';

export default function Updateproduct(props)
{
   
  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  const navigate=useNavigate();
  const [show, setShow]=useState(false)
  const handleShow = () => 
  {
    
    if(localStorage.getItem('user-info'))
    {
    setShow(true);
    setusermobilenumber(props.usermobilenumber)
    setusername(props.username)
    setcusname(props.cusname)
    setproductid(props.productid)
    setproductimg(props.productimg)
    setprodDes(props.prodDes)
    setuserareacode(props.userareacode)
    setcurrentdata(props.currentdata)
    setshopname(props.shopname)
    setprodname(props.prodname)
    setprodprice(props.prodprice)
    setaddress(props.address)
    setcusaddress(props.useraddress)
    setcuscity(props.usercity)
    setshopmobilenumber(props.shopmobilenumber)
    setgetcity(props.city)
    setbuyerid(props.buyerid)
    setsellerid(props.sellerid)
    setdiscount(props.discount)
    setfinalprice(props.finalprice)
  }
  else
  {
    navigate('/login')
  }

  }
  const handleClose = () => setShow(false);

const [getusermobilenumber,setusermobilenumber]=useState('')
const [getusername,setusername]=useState('')
const [getcusname,setcusname]=useState('')
const [getproductid,setproductid]=useState('')
const [getproductimg,setproductimg]=useState('')
const [getprodDes,setprodDes]=useState('')
const [getuserareacode,setuserareacode]=useState('')
const [getcurrentdata,setcurrentdata]=useState('')
const [getshopname,setshopname]=useState('')
const [getprodname,setprodname]=useState('')
const [getprodprice,setprodprice]=useState('')
const [getaddress,setaddress]=useState('')
const [getcusaddress,setcusaddress]=useState('')
const [getcuscity,setcuscity]=useState('')
const [getordercomment, setordercomment]=useState()
const [getshopmobilenumber,setshopmobilenumber]=useState('')
const [getcity,setgetcity]=useState('')
const [getbuyerid,setbuyerid]=useState('')
const [getsellerid,setsellerid]=useState('')
const [getdiscount,setdiscount]=useState('')
const [getfinalprice,setfinalprice]=useState('')
const [getresponse,setresponse]=useState([])

function editProduct()
{
 //console.log("cusorder",props.cusname)
  //console.log("cussorderget",getusermobilenumber,getshopmobilenumber)
let data={getsellerid,getbuyerid,getusermobilenumber,getproductid,getproductimg,getprodDes,getuserareacode,getcurrentdata,
getshopname,getprodname,getprodprice,getaddress,getcusaddress,getcuscity,getordercomment,getshopmobilenumber,getcity,getusername,getcusname,getdiscount,getfinalprice}
      console.log("productdata",data)
        try{
            //let pResult= fetch("//allstoresprice.com:3443/orderproduct",
            let pResult= fetch(window.siteUrl+"/editproductdetails",
           
            
             {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'bearar'+' '+localStorage.getItem('auth')
              },
              body: JSON.stringify(data)
            }).then((pResult) => {
              //console.log("Orderproduct",data)
              //console.log("order product status", pResult)
               setresponse(pResult.status)
              pResult.json().then((resp) => {
                console.log("Product Updated", resp)
              //  localStorage.setItem('shop-name',getShopNameList)
                // setshowshopName(name1)
              })
              
            })
            handleClose()
            document.location.reload();
            // navigate("/"+getShop)
          }catch(error)
          {
            console.log("order update error",error)
          }
        }

        useEffect(()=>
{
  if(getdiscount>=1)
  {
    let actualprice=getprodprice*getdiscount/100
    setfinalprice(Math.round(getprodprice-actualprice))
}else
{
  setfinalprice(getprodprice)
}
})
    return(
<>
<FiEdit2  style={{cursor:"pointer"}} className="text-primary "onClick={handleShow} />
 {/* <PiShoppingCartThin onClick={handleShow} className='fs-4'/>  */}
  {/* <Button variant="outline-primary" onClick={handleShow} className='orderbutton fs-7 '>Order</Button>   */}

<Modal  show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"className='fs-6'>
        Order Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Order Details</h4> */}
        <div className='imageheightorder'>
             <Card.Img className=' imagehorder'variant="top"  src={`${props.productimg}`} roundedCircle/>
      
    </div>
    {/* <div>
    <InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Shop Name</InputGroup.Text>
     <input for="name" className="form-control  text-center  fs-7" id="shopName" value={props.shopname.toUpperCase()}disabled='true'></input> 
	   </InputGroup>
     </div> */}
        <div>
        <InputGroup className="mb-3 my-2 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Product Name</InputGroup.Text>
	      <input maxLength={15} for="name" className="form-control  text-center  fs-7" id="productname" value={getprodname} onChange={(e)=>setprodname(e.target.value)}></input> 
       </InputGroup>
		  </div>
      {/* <div className="form-group my-1">
	   <label for="name" className="form-control" id="areaPin">{`${props.userareacode}`}</label> 
	   </div> */}
     
     <div>
     <InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Price ₹</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)}  onChange={(e)=>setprodprice(e.target.value)}type="number" placeholder="Price Value" 
className="form-control  text-center  fs-7" value ={getprodprice}/>
</InputGroup>
	   </div>
     <div>
     {/* <inputbox for="name" className="form-control my-1" id="price">{`${props.prodprice}`}</inputbox>  */}
     <InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Discount %</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)}  onChange={(e)=>setdiscount(e.target.value)}type="number" placeholder="Price Value" 
className="form-control  text-center fs-7 " value ={`${getdiscount}`}/>
</InputGroup>
       </div>
       <div>
     <InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success' >Final Price ₹</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)}  type="number" placeholder="Price Value" 
className="form-control  text-center fs-7 " value ={getfinalprice} disabled='true'/>
</InputGroup>
	   </div>
     
        <FloatingLabel controlId="floatingTextarea2" label="Edit Product Details">
        <Form.Control  maxLength={150} onChange={(e)=>setprodDes(e.target.value)}
          as="textarea"
          placeholder="Leave a comment here" 
          value={getprodDes}
          className='border border-dark'
          style={{ height: '120px' }}>{``}</Form.Control>
        
      </FloatingLabel>

      </Modal.Body>
      <Modal.Footer>
      <Button className="bg-success"onClick={editProduct}>Update</Button>
        <Button className="bg-danger"onClick={handleClose}>Close</Button>
        
      </Modal.Footer>
    </Modal>

</>
    )
}