import Toast from 'react-bootstrap/Toast';
import { Bounce } from 'react-toastify';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
export default function Toastnot()
{

  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  const ordernumber="Order has been received"
  const shopnumber="New shop opened in your area"
  const productnumber="New products available in your area"
let [getshopcount,setshopcount]=useState(0)
let [getproductcount,setproductcount]=useState(0)
let [getcounter,setcounter]=useState(0)
const [getuserid,setuserid]=useState('')
const[getstatus,setstatus]=useState('')


  useEffect(()=>
  {
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      setproductcount(parseVal.newProduct_count)
      setshopcount(parseVal.newShop_count)
      let buyerid= parseVal.cus_id
          setuserid(buyerid)
     let counter=0
      if(parseVal.newProduct_count>0)
      {
        setcounter(counter=counter+1)
      }
      if(parseVal.newShop_count>0)
      {
        setcounter(counter=counter+1)
      }

    }
  },)
 
  
  const notify = () => 
{
//   toast.success(`${ordernumber}`, {
//   position: "top-right",
// autoClose: false,
// hideProgressBar: false,
// closeOnClick: true,
// pauseOnHover: true,
// draggable: true,
// progress: undefined,
// theme: "light",
// transition: Bounce,
//     });

if(getshopcount>0)
{
    toast.success(`${getshopcount} ${shopnumber}`, {
      position: "top-right",
autoClose: 10000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "light",
transition: Bounce,
      });
    }
    if(getproductcount>0)
{
      toast.success(`${getproductcount} ${productnumber}`, {
        position: "top-right",
autoClose: 10000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "light",
transition: Bounce,
        });
      }

updatenotificationcount();
      
}

function updatenotificationcount()

    {
     let userid=getuserid
 console.log("userid",userid)
    
 fetch(`${window.siteUrl}/updateUserNotification/${userid}`,
        //fetch(`//allstoresprice.com:3443/showorderproduct/${buyerid}`,
        {
          method:'POST',
          headers: {
          authorization:'bearar'+' '+localStorage.getItem('auth')
        }
      })
        .then((result)=> 
       {
         result.json().then((resp)=>{
            
           console.log("notifcatiion udpate",resp)
           setstatus(resp)
          
         })
       }
       )
      }

    return(
      <div>
      <div onClick={notify}>Notify!<Badge bg="secondary">{getcounter}</Badge></div>
      <ToastContainer   />
      
      
       
    </div>
    );
}