
import { useEffect, useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink,useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import ShopHomePage from './ShopHomePage';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Captureimg from './Captureimg';


export default function AddProduct(props)
{
 //const siteUrl="http://localhost:4500";
 //const siteUrl="//allstoresprice.com:3443";

    const navigate=useNavigate();
    const [getimage, setimage]=useState('')
    const [getDesc, setDesc]=useState()
    const [getProductName, setProductName]=useState('')
    const [getPrice, setPrice]=useState(0)
    const [getUserName, setUserName]=useState()
    const [getUserNumber, setUserNumber]=useState()
    const [getaddress, setaddress]=useState()
    const [getcity, setcity]=useState()
    const [getAreaPinCode, setAreaPinCode]=useState()
    const [getShopName, setShopName]=useState()
    const [getUpdateShopName]=useState()
    const [getcounter, setcounter]=useState(0)
    const [show, setshow] = useState(true)
    const [getstatus, setstatus]=useState()
    const [getresponse,setresponse]=useState([])
    const [getenable, setdisable]=useState(true)
    const [getcusid, setcusid]=useState('')
    const [getdiscount, setdiscount]=useState(0)
    const [getcurrentprice, setcurrentprice]=useState('')
    const [radioValue, setRadioValue] = useState('product');
    

    
    const price=useRef('')
    const discountamt=useRef('')
    const desc=useRef('')
    const img=useRef('')
    const productN=useRef('')

    const params= useParams()
    

//     function addProductDetails()
//     {
// navigate('/addproduct')
//     }
// function SetData()
// {
//     const loc=localStorage.getItem('user-info')
// const parseVal=JSON.parse(loc)
// console.log(parseVal)
//     setUserName(parseVal.user_name)
//     setUserNumber(parseVal.user_number)
//     setAreaPinCode(parseVal.user_areaCode)

// }
//  function getData()
// {
//   SetData()
//     // console.log("fileData",getimage,getDesc,getPrice,getUserName,getUserNumber,getAreaPinCode)
//       addProductDetails()
// }

let imgsize=0;
try{
imgsize=getimage.size
}catch
{

}

async function addProductDetails()
{
  
    const name=params.name
    // console.log("param:-",name)
     const shopname= name.replace(/ /g, '')
   const loc=localStorage.getItem('user-info')
   let shopname1=localStorage.getItem('shop-name')
   let len=shopname1.length;
   let showShopdetails;
   let showShopname

  //  for (let i=0;i<=len-1;i++)
  //  {
  //   showShopdetails=shopname1[i].split(":")
  //   console.warn("shoplis1",shopname1[i])
  //   if(showShopdetails[0].includes(shopname))
  //   {
  //     showShopname=showShopdetails[1]
  //     console.log("shoplis",showShopdetails[1])
  //   }
  //  }
  //  const locshop=localStorage.getItem('shop-name')

  const min = 1;
  const max = 1000000;
  const rand = min + Math.ceil((Math.random() * (max - min)));

const parseVal=JSON.parse(loc)
const parseValshop=JSON.parse(shopname1)
//console.log("shoplocal",parseValshop)
const productID= name.substring(0,4)+rand

// e.preventDefault()
// window.location.reload(false);
  const  formData= new FormData();
  formData.append('getimage',getimage)
  formData.append('getDesc',getDesc)
  formData.append('getProductName',getProductName)
  formData.append('getPrice',getPrice)
  formData.append('getdiscount',getdiscount)
  formData.append('getcurrentprice',getcurrentprice)
  formData.append('getUserName',parseVal.user_name)
  formData.append('getUserNumber',parseVal.user_number)
  formData.append('getAreaPinCode',parseVal.user_areaCode)
  formData.append('getaddress',parseVal.address)
  formData.append('getcity',parseVal.City)
   formData.append('getShopName',shopname)
   formData.append('productid',productID)
   formData.append('cusid',parseVal.cus_id)
   formData.append('imagetype',radioValue)

   for (let i=0;i<=parseValshop.length-1;i++)
   {
    let url=parseValshop[i].shop_url
    //console.log("url",url)
    if(url==name)
    {
   formData.append('getUpdateShopName',parseValshop[i].shop_name)
  }
  }
  
 
  let data={getimage,getDesc,getPrice,getUserName,getUserNumber,getAreaPinCode,getShopName,getUpdateShopName,getProductName}
  //  console.log([...formData.entries()])
   console.log("Data123",getimage)
//axios.post(window.siteUrl+'/addProducts?foldername='+ shopname,formData,
axios.post(window.siteUrl+'/addProducts',formData,
 //axios.post('//allstoresprice.com:3443/addProducts',formData,
 {
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth'),
  foldername:shopname,
  mobnumber:parseVal.user_number
}
})
 
 
.then(res=>setstatus(res.status),
res1=>console.log(res1))
.catch(err=>console.log(err))
setcounter(getcounter+1)
props.addproduct(getcounter)
setTimeout(() => {
  
  setshow(false)
  try{
  price.current.value='';
  discountamt.current.value='';
  desc.current.value='';
  
  productN.current.value='';
  }
  catch{
    
  }
}, 4000)


        // try{
        //     let pResult= await fetch("http://localhost:4500/addProducts",
            
        //     //let pResult= fetch("//allstoresprice.com:3443/leftPro",
            
        //      {
        //       method: 'POST',
        //       headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //       },
        //       body: formData
        //     }).then((pResult) => {
        //       console.log("product result", pResult)
        //       setresponse(pResult.status)
        //       pResult.json().then((resp) => {
        //         console.log("product list", resp)
        //       })
        //     })
        //   }catch(error)
        //   {
        //     // console.log("update error",error)
        //   }
          
        }

       useEffect(()=>
       {
        console.log("getimage",getimage)
        // if(getDesc!=null && getPrice!=null&& getimage!=null&& imgsize<5000000&&getdiscount!=null)
        if(getDesc!=null && getimage!='')
        {
          setdisable(false)
        }
       }) 
useEffect(()=>
{
  if(getdiscount>=1)
  {
    let actualprice=getPrice*getdiscount/100
    setcurrentprice(Math.round(getPrice-actualprice))
}else
{
  setcurrentprice(getPrice)
}
})
const handleImageCapture = (file) => {
  setimage(file);
};

const handleRadioChange = (event) => {
  setRadioValue(event.target.value);
};


const [resizedImage, setResizedImage] = useState(null);

const resizeImage = (image) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const width = 370; // desired width
  const height = 250; // desired height
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
  return canvas.toDataURL();
};
useEffect(() => {
  if (getimage) {
    const image = new Image();
    image.onload = () => {
      const resizedImage = resizeImage(image);
      setResizedImage(resizedImage);
    };
    image.src = URL.createObjectURL(getimage);
  }
}, [getimage]);


return(
  //<Container fluid='lg'
    <Container fluid='lg' className='my-3 addproduct bg-light'>
    <Row className='rowname'>
     
      <Col xs={12}>
        {
          getstatus==200 && show!=false?
          <div>
      <label for="floatingInput" className='text-success fw-bold'>Upload successfully</label>
      </div>
      :null
        }
        
{/* <form className='container formC col-sm-12 col-lg-12 col-md-4 col-12 mt-1' > */}

<input className='m-1'
        type="radio"
        id="product"
        name="status"
        value="product"
        checked={radioValue === 'product'}
        onChange={handleRadioChange}
      />
      
      <label htmlFor="a" className='my-2 'style={{ marginRight: '20px' }}>Product</label>

      <input 
      className='m-1'
        type="radio"
        id="poster"
        name="status"
        value="poster"
        checked={radioValue === 'poster'}
        onChange={handleRadioChange}
      />
      <label htmlFor="b" className='my-2 'style={{ marginRight: '20px' }}>Poster/Banner</label>

     
      {/* {radioValue === 'b' && <p>B is on</p>} */}
      {radioValue === 'product' &&
      <>
<div className="form-group d-flex justify-content-center">

<div className="col-sm-10 col-lg-10 col-md-10 col-12  align-self-center textformat">

<label for="floatingInput" className='loginLb'>Add Product</label>
<Row >
<Col xs={12} sm={12}>
<Captureimg 
    onImageCapture={(image, file) => console.log("imagephoto", image, file)} 
    onFileSet={(file) => handleImageCapture(file)} 
  />
  {
    console.log("imagefile",getimage)
  }



{/* <Form.Group controlId="formFile" className="mb-2 ">
        <Form.Label>Upload product image</Form.Label>
        <Form.Control type="file" ref={img} className='border border-dark'onChange={(e)=>setimage(e.target.files[0])}/>
        {
          console.log("imagge",getimage)
        }
        {imgsize>5000000?
        <label for="floatingInput" className='text-danger'>Size should not be more than 5 mb</label>
        :
        null
        
      }
      </Form.Group> */}
      </Col>
      <Col xs={12} sm={12}>
        <FloatingLabel  controlId="floatingTextarea2" label="Shop/Product Details">
        <Form.Control maxLength={150}
          as="textarea"
          placeholder="Leave a comment here" ref={desc} className='border border-dark my-2'onChange={(e)=>setDesc(e.target.value)}
          style={{ height: '150px' }}
        />
      </FloatingLabel>
      
        </Col>  
      </Row>
      <Row className='rowname'>
<Col xs={7} sm={4} >
     
<input maxLength={15} ref={productN} onChange={(e)=>setProductName(e.target.value)}type="text" placeholder="Product Name" 
className="form-control m-1 text-center fs-7 border border-dark inputwidth"></input>
</Col>
<Col xs={5} sm={3}>
<InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success bg-light' >₹</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)} ref={price} onChange={(e)=>setPrice(e.target.value)}type="number" placeholder="Price Value" 
className="form-control  text-center fs-7 " ></input> 
</InputGroup>
</Col>
<Col xs={7} sm={2}>
<InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success bg-light' >%</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)} ref={discountamt} onChange={(e)=>setdiscount(e.target.value)}type="number" placeholder="Discount" 
className="form-control  text-center fs-7  " ></input> 
 </InputGroup>
</Col>
<Col xs={5} sm={3} >
<InputGroup className="mb-3 my-1  border border-dark">
<InputGroup.Text  className='fw-bold text-success bg-light'>₹</InputGroup.Text>
 <input onInput={(e) => e.target.value = e.target.value.slice(0, 9)}  type="number" placeholder="Current Price" 
className="form-control  text-center fs-7 " value={getcurrentprice} disabled='true'></input> 
</InputGroup>

</Col>

</Row>
{/* <PropsFunctionCall data={"ok"}/> */}

<Button type="submit" className="m-1 bg-primary"disabled={getenable}onClick={addProductDetails}variant="secondary">Add</Button>{' '}  

 {/* <NavLink to = "/otp"><Button onClick={signup}variant="secondary">Continue</Button>{' '} </NavLink>   */}
</div>
</div>
</>
}

{radioValue === 'poster' && 
<>
<div className="form-group d-flex justify-content-center">

<div className="col-sm-10 col-lg-10 col-md-10 col-12  align-self-center textformat">

<label for="floatingInput" className='loginLb'>Add Poster</label>
<Row >
<Col xs={12} sm={5}>
{/* <Captureimg 
    onImageCapture={(image, file) => console.log("imagephoto", image, file)} 
    onFileSet={(file) => handleImageCapture(file)} 
  />
  {
    console.log("imagefile",getimage)
  } */}



<Form.Group controlId="formFile" className="mb-2  ">
  <Form.Label>Upload product image</Form.Label>
  {/* <img
    src={resizedImage || "https://via.placeholder.com/370x250.png?text=350x216"}
    alt="Image"
    className="ms-2"
    style={{
      maxWidth: "100%",
      maxHeight: "250px",
      objectFit: "contain",
    }}
  /> */}
  <img
  src={getimage ? URL.createObjectURL(getimage) : "https://via.placeholder.com/300x186.png?text=300x186"}
  alt="Image"
  className="ms-2"
  style={{
    maxWidth: "100%",
    maxHeight: "186px", // adjust this value to your liking
    objectFit: "contain",
  }}
/>
  <div className="d-flex my-1">
  
    <Form.Control type="file" ref={img} className='border border-dark' onChange={(e)=>setimage(e.target.files[0])}/>
    
  </div>
  {imgsize > 5000000 ? (
    <label for="floatingInput" className="text-danger">
      Size should not be more than 5 mb
    </label>
  ) : null}
</Form.Group>

      </Col>
      <Col xs={12} sm={7}>
        <FloatingLabel  controlId="floatingTextarea2" label="Shop/Product Details">
        <Form.Control maxLength={150}
          as="textarea"
          placeholder="Leave a comment here" ref={desc} className='border border-dark'onChange={(e)=>setDesc(e.target.value)}
          style={{ height: '150px' }}
        />
      </FloatingLabel>
      
        </Col>  
      </Row>
      <Row className='rowname'>
{/* <Col xs={7} sm={4} >
     
<input maxLength={15} ref={productN} onChange={(e)=>setProductName(e.target.value)}type="text" placeholder="Product Name" 
className="form-control m-1 text-center fs-7 border border-dark inputwidth"></input>
</Col>
<Col xs={5} sm={3}>
<InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success bg-light' >₹</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)} ref={price} onChange={(e)=>setPrice(e.target.value)}type="number" placeholder="Price Value" 
className="form-control  text-center fs-7 " ></input> 
</InputGroup>
</Col>
<Col xs={7} sm={2}>
<InputGroup className="mb-3 my-1 border border-dark">
<InputGroup.Text className='fw-bold text-success bg-light' >%</InputGroup.Text>
<input onInput={(e) => e.target.value = e.target.value.slice(0, 9)} ref={discountamt} onChange={(e)=>setdiscount(e.target.value)}type="number" placeholder="Discount" 
className="form-control  text-center fs-7  " ></input> 
 </InputGroup>
</Col>
<Col xs={5} sm={3} >
<InputGroup className="mb-3 my-1  border border-dark">
<InputGroup.Text  className='fw-bold text-success bg-light'>₹</InputGroup.Text>
 <input onInput={(e) => e.target.value = e.target.value.slice(0, 9)}  type="number" placeholder="Current Price" 
className="form-control  text-center fs-7 " value={getcurrentprice} disabled='true'></input> 
</InputGroup>

</Col> */}

</Row>
{/* <PropsFunctionCall data={"ok"}/> */}

<Button type="submit" className="m-1 bg-primary"disabled={getenable}onClick={addProductDetails}variant="secondary">Add</Button>{' '}  

 {/* <NavLink to = "/otp"><Button onClick={signup}variant="secondary">Continue</Button>{' '} </NavLink>   */}
</div>
</div>
</>
}
{/*  */}
{/* </form> */}
</Col>

</Row>
</Container>

)


}