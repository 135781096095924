import Container from 'react-bootstrap/Container';
export default function Aboutus()
{

return(
    <Container fluid='md' className='my-3 bg-light'>
<div>

<h2>About Us</h2>
<p>Online product details</p>
<p>If you have any query regrading Site, Advertisement and any other issue, please feel free to contact at <strong>allstoresrice@gmail.com</strong></p>


</div>
</Container>
)

}