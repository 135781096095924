import Container from 'react-bootstrap/Container';
export default function Contactus()
{

return(
    <Container fluid='md' className='my-3 bg-light'>
<div>

<h2>Contact Us</h2>
<p>If you have any query regrading Site, Advertisement and any other issue, please feel free to contact at <strong>allstoresrice@gmail.com</strong></p>

    
</div>
</Container>
)

}