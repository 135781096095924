import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink,useNavigate, useParams } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import PropVal from './PropVal';
import Usershop from './UserShop';
import Form from 'react-bootstrap/Form';
import { FaHome } from "react-icons/fa";
import Badge from 'react-bootstrap/Badge';
import Toastnot from './Toastnot';
import { IoIosNotificationsOutline } from "react-icons/io";
import Ordercount from './Ordercount';

// import CrShop from './CrShop';

export default function Header()
{
  
  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  // let navigate = useNavigate();
  // useEffect(()=>
  // {
    
  //   navigate('/shop')
  // }) 
  
  const [show, setShow] = useState(false);
  const [getShopNameDetails,setShopNameDetails]=useState('') 
  
  let [getShopName]=useState('') 
  let [getShopUrl]=useState('') 
  // let showShopName 
  let showShopName1
  let shopNameList 
  let shopUrlList
  const [showShopName,setDisplayShop]=useState('') 

  const [getMobile,setMobileNumber]=useState('') 
  const [getuserID,setuserID]=useState('') 

  const [getareaCode,setareaCode]=useState('') 
  const [getAddress,setAddress]=useState('') 
  const [getshowDetails,setshowDetails]=useState('') 
  const [getcity,setcity]=useState('') 

  const [getuserName,setuserName]=useState('') 
  const [getcategory,setcategory]=useState('Select Type of Shop') 
  const [getothercategory,setothercategory]=useState('')
  let[shoplen]=useState('')
  const [getenable, setdisable]=useState(true)
  
  
  let [getShopNameList]=useState('')
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
   const navigate =  useNavigate();
  const [getresponse,setresponse]=useState([])
  let [getsurl]=useState([])
  
  let [getselectedshopName]=useState()
  const [showAddProduct, setShowAddProduct] = useState(false);
  
  let geturlList
  let paramname=""
  const count=1
  //console.log("cat",getcategory)
  
  let userData
  const params= useParams()
  const {name}=params
  //console.log("list",getsurl)
  let shouldVisiblecreateButton
  const createshopwithoulogin=()=>
  {
    navigate('/login')
  }
if(localStorage.getItem('user-info'))
{
  userData=localStorage.getItem('user-info')
    const details= JSON.parse(userData);
    //console.log("details",details)

    // showShopName1 =localStorage.getItem('shop-name')
    //  console.log("shoplistset",displayShop)
    //  showShopName= JSON.parse(showShopName1);
    
     //console.log("shoplist",showShopName.length)
     if(localStorage.getItem('shop-name'))
     {
    const shopData=localStorage.getItem('shop-name')
    shoplen= JSON.parse(shopData);
      
      //console.log("shoplen",shoplen.length)
     }
    
    // shouldVisiblecreateButton=showShopName.filter(n=>n)

   

    // let abc=showShopName.split(":")
    //  geturlList=abc[0]
    //  console.log("url",geturlList)
  //   shopurlData=localStorage.getItem('shopurl').split(",")
  // shopnameData=localStorage.getItem('shop-name').split(",")
  // index=shopurlData.indexOf(name)
}


// setshowshopName(shopnameData[index])
    // setMobileNumber(details.user_number)
    // setuserName(details.user_name)
    
    
    

    // shopurlDatalist= getShop.split(",")
    // shopurlDatalist=shopurlDatalist.filter(n=>n)
    // shopnameDatalist=getShopname.split(",")
    // shopnameDatalist=shopnameDatalist.filter(n=>n)
    // console.log("arry",shopnameDatalist,shopurlDatalist)
    // size=shopurlDatalist.length
    // console.log("lne",size)

    
  function showShowNameList()
  {
    let shopdetailsArray=showShopName.split(",")
    shopdetailsArray= shopdetailsArray.filter(n=>n)
    
  }

  function logout()
  {
    localStorage.clear()
    
    navigate("/")
    document.location.reload();
  
  }
  
  async function updatedata()
  {
    await updateShop()
    await handleClose()
    await nav();
  }
 
 
  async function nav()
  {
    await navigate("/" + getShopUrl, { state: { showAddProduct: true } })
    
   setTimeout(() => {
      document.location.reload();
    }, 300)
  }
  // useEffect(()=>
  // {
  //   document.location.reload();
  // },[count+1])

  useEffect(()=>
     {
      
      if(localStorage.getItem('user-info'))
  {
    const userData=localStorage.getItem('user-info')
    const details= JSON.parse(userData);
    setMobileNumber(details.user_number)
    setareaCode(details.user_areaCode)
    setuserName(details.user_name)
    setAddress(details.address)
    setshowDetails(details.showDetails)
    setcity(details.City)
    setuserID(details.cus_id)
   
    }
    
  },[getMobile])

    
    
    // useEffect(()=>
    // {
    //   if(localStorage.getItem('user-info'))
    //   {
    //   setshowshopName(shopnameData[index])
    // }
    // },[])

    useEffect(()=>{
      if(localStorage.getItem('user-info')){
        let userdata=localStorage.getItem('user-info')
        let parseVal=JSON.parse(userdata)
        let usernumber= parseVal.user_number
        
      fetch(`${window.siteUrl}/userdata/${usernumber}`,{
        headers: {
        authorization:'bearar'+' '+localStorage.getItem('auth')
      }
    })

      //allstoresprice.com:3443
      //fetch(`//allstoresprice.com:3443/userdata/${usernumber}`)
      .then((result)=>
      
     {
       result.json().then((resp)=>{
        // console.log("head hlist1",resp)
        //  setcount(getplist.length) 
             localStorage.setItem('user-info',JSON.stringify(resp[0]))
             setshowDetails(resp[0].showDetails)
            
       })
     }
     )
    }
   },[])

   useEffect(()=>{
    if(localStorage.getItem('user-info')){
      let userdata=localStorage.getItem('user-info')
      let parseVal=JSON.parse(userdata)
      let usernumber= parseVal.user_number
      let userID=parseVal.cus_id
      //allstoresprice.com:3443
    fetch(`${window.siteUrl}/getshoplist/${userID}`,
    
    //fetch(`//allstoresprice.com:3443/getshoplist/${usernumber}`,
    {
      headers: {
      authorization:'bearar'+' '+localStorage.getItem('auth')
    }
  }
    )
    //fetch("//allstoresprice.com:3443/amleftcopy").then((result)=>
    .then((result)=>
     
   {
     result.json().then((resp)=>{
     //  console.log("shop list",resp)
      //  setcount(getplist.length) 
          //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
           setDisplayShop(resp)
           localStorage.setItem('shop-name',JSON.stringify(resp))
          
     })
     
   }
   )
  }
  
 },[])
    
const selectAction=(shop)=>
{
  
 // console.log("clickedname",shop)
  let listShop=shop.split(":")
  shopNameList=listShop[1]
  
  shopUrlList=listShop[0]
 
navigate("/"+shopUrlList)
//  setDisplayShop(shopNameList)
document.location.reload();
}


  async function updateShop()
    {
      const min = 1;
    const max = 10000;
    const rand = min + Math.ceil((Math.random() * (max - min)));
      //console.log("first",getShopNameDetails)
      getShopUrl= getShopNameDetails.replace(/ /g, '')
      getShopUrl=getShopUrl+rand
    
    // getShopName=(getShopUrl+":"+getShopNameDetails)
    //  console.log("rulname",getShopName)
     
    //  if(localStorage.getItem('user-info'))
    //  {
    //  console.log("localname",localStorage.getItem('shop-name'))
    //  console.log("local shop",getShopName , localStorage.getItem('shop-name'))
    //  getShopNameList=(getShopName+","+localStorage.getItem('shop-name'))
    //  console.log("gtshop",getShopNameList)
     
    // }
let shoptype
    if(getcategory!="Other")
    {
      shoptype=getcategory
    }else
    {
      shoptype=getothercategory
    }
      let data={getShopUrl,getShopNameDetails,getMobile,shoptype,getareaCode,getAddress,getshowDetails,getcity,getuserID}
      console.log("shopdata",data)
        try{
             let pResult= fetch(window.siteUrl+"/updateShop",
            //allstoresprice.com:3443
            //let pResult= fetch("//85.31.232.240:8080/updateShop",
           //let pResult= fetch("//allstoresprice.com:3443/updateShop",
             {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': 'bearar'+' '+localStorage.getItem('auth')
              },
              body: JSON.stringify(data)
            }).then((pResult) => {
             // console.log("body data",data)
              //console.log("shop list", pResult)
              setresponse(pResult.status)
              pResult.json().then((resp) => {
                console.log("Post lefpro", resp)
                //localStorage.setItem('shop-name',getShopNameDetails)
                // setshowshopName(name1)
              })
              
            })
            // navigate("/"+getShop)
          }catch(error)
          {
            console.log("update error",error)
          }
          
        }
      
//         useEffect(()=>
//         {
//           if(getresponse=='200')
//           {
// navigate('/shop')
// }else{
//   console.log("some issue")
// }

//         },[getresponse])

useEffect(()=>
       {
        if(getcategory!="Select Type of Shop" && getShopNameDetails.length>0)
        {
          setdisable(false)
        }else
        {
          setdisable(true)
        }
       }) 

      

    return(
      <>
        
      <Container fluid  style={{backgroundColor: "#20c997"}} >
      {/* style={{fontSize:"20px"}} */}
      {/* f1f2eb */}
      <div>
     
        {/* <label for="floatingInput" className='text-danger h5 my-2'>Get Your Local Products Online</label> */}
        </div>
        <Navbar expand="lg" className="headermargin">
  <Navbar.Brand href="/" className='text-primary font-monospace'>
    <span className="ms-2">AllStoresPrice.com</span> {/* Company name */}
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  {/* {localStorage.getItem('user-info')?
  null:
  <Navbar.Brand  className='text-primary font-monospace'>
  <Button variant="outline-danger" className='btn-sm fw-bold fs-6' onClick={createshopwithoulogin}>
       Create Shop
     </Button> 
     </Navbar.Brand>
} */}
  <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="justify-content-end my-1" style={{ width: "100%",fontSize:"18px"}}>
            {/* <Nav.Link href="/creatShop" >My Shop</Nav.Link>  */}
            {/* <Button variant="primary" onClick={handleShow}>
        Create Shop
      </Button>  */}
            <Navbar.Brand href="/" className='text-primary font-monospace'>
    <FaHome /> {/* Hamburger menu icon */}
    
  </Navbar.Brand>
            {
             userData?shoplen.length>0?
             
             <NavDropdown title={name==undefined?"Shops":name} id="basic-nav-dropdown ">
              {
                showShopName && showShopName?.map((item)=>( 
                <>
              <NavDropdown.Item id="nav-dropdown" onClick={()=>selectAction(item.shop_url)}> {item.shop_url}</NavDropdown.Item>
              {
                
              //   !getsurl.includes(item.split(":")[0])?
              // getsurl.push(item.split(":")[0])
              // :null
              }
              </>  
              ))}
              
            <NavDropdown.Divider />
      <Button variant="outline-primary" onClick={handleShow}>
        Create Shop
      </Button> 
      </NavDropdown> 
     :<Button variant="outline-primary" onClick={handleShow}>
       Create Shop
     </Button> 
   :null
     
}
      {/* <label for="floatingInput" >{" "+getuserName}</label> */}
            
            {localStorage.getItem('user-info')?
           <>
           <Nav.Link href="/profile">Profile</Nav.Link>
           
          {/* <Ordercount/> */}
               <Nav.Link>
              <Toastnot/>
               </Nav.Link> 
            <NavDropdown title={getuserName} id="basic-nav-dropdown" >
              
              {/* <NavDropdown.Item href="/selectplan">
                Select Plan
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/feedback">
                Feedback
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logout} >
              Logout
              </NavDropdown.Item>
            </NavDropdown>
            </>
            
            :
            <>
            <Button variant="outline-primary" className='btn-sm fw-bold' onClick={createshopwithoulogin}>
       Create Shop
     </Button> 
            <Nav.Link href="/login" className='text-white'>Login</Nav.Link>
            
            {/* <Nav.Link href="/selectplan">Promotion</Nav.Link> */}
            <Nav.Link href="/signup" className='text-white'>SignUp</Nav.Link>
            </>
            
}
          </Nav>
        </Navbar.Collapse>
        

    </Navbar>
    </Container>

    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Shop Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <input maxLength={30} onChange={(e)=>setShopNameDetails(e.target.value)}type="text" placeholder="Enter Shop Name" 
className="form-control text-center fs-8 shopName my-1" id="userName"></input>
{getShopNameDetails.length<1?
  <label for="floatingInput" className='text-danger'>Enter shop</label>
:
null    
}

 <Form.Select aria-label="Default select example" className='my-2 fs-6' onChange={(e)=>setcategory(e.target.value)}>
 
      <option>Select Type of Shop</option>
      <option value="Grocery">Grocery</option>
      <option value="Food">Food</option>
      <option value="Stationary">Stationary</option>
      <option value="Profession">Profession</option>
      <option value="HomeMade Product">HomeMade Product</option>
      <option value="Travelling">Travelling</option>
      <option value="Travelling">Interior Design</option>
      <option value="Other">Other</option>
    </Form.Select>
  { getcategory=="Select Type of Shop"?
  <label for="floatingInput" className='text-danger'>Select Shop Type</label>
  :
  null
  }
{getcategory=="Other"?
    <input maxLength={15} onChange={(e)=>setothercategory(e.target.value)}type="text" placeholder="Enter Other category Name" 
className="form-control text-center fs-8 otherCategory my-1" id="otherCategory"></input>
:null

}
{getothercategory.length<1 && getcategory=="Other"?
  <label for="floatingInput" className='text-danger'>Enter Other Category Name</label>
  :
  null
}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          
         <Button variant="success" onClick={updatedata} disabled={getenable}>
            Save
          </Button>
       
        </Modal.Footer>
      </Modal>
      
    </>
    )
}