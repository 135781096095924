import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import shoping1 from './1.jpg'
import { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import Toastnot from './Toastnot';

export default function BannerAds_1(props)
{
  
  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  const [getshoplist,setshoplist]=useState('');
  const [getbannerlist,setbannerlist]=useState('')
//console.log("fullbanner",props.banner_1ads)
  // useEffect(()=>
  // {

  //   let pin=null
  //   if(localStorage.getItem('user-info'))
  //   {
  //    const pinjson=localStorage.getItem('user-info')
  //      pin=JSON.parse(pinjson).user_areaCode
       
  //   }
  //   //console.log("pinid",pin)
  //   //fetch(`//allstoresprice.com:3443/getstoreimglist/${pin}`,
  //   fetch(`${window.siteUrl}/getstoreimglist/${pin}`,).then((result)=>
  
  // {
  //   result.json().then((resp)=>{
  //     //console.log("shop liststore",resp)
  //     setshoplist(resp)
  //    //  setcount(getplist.length) 
  //        //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
         
  //         // localStorage.setItem('shop-name',JSON.stringify(resp))
         
  //   })
    
  // }
  // )
  // },[])
 
  useEffect(()=>
  {
    const shuffledList = props.fulldata.sort(() => Math.random() - 0.5);
    setbannerlist( shuffledList)
  })
  


    const desk=4
  const mobile=2
      // var settings = {
      //     dots: true,
      //     infinite: true,
      //     speed: 300,
      //     slidesToShow: 4,
      //     slidesToScroll: 4,
      //     autoplay: true,
      //     autoplaySpeed: 3000,
          
  
      //   };
      var settings = {
        dots: true,
        
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll:3,
        initialSlide: 0,
        
        
        autoplay: true,
         autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
              
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow:2,
              slidesToScroll: 2,
              initialSlide: 4,
              infinite: true,
              dots: false,
              
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll:1,
              infinite: true,
              dots: false,
             
            }
          }
        ]
      };
      return(
  <>
  <Container fluid  className='my-1 p-1 ' >
  {props.city?
   <div className='text-start text-success fw-bold'>SHOPS IN {props.city.toUpperCase()}</div>
   :null}
  <Row xs={1} md={1} >
  <div className='text-white sliderim'>
     <Slider {...settings}>
        {getbannerlist &&
      getbannerlist.length > 0 &&
      getbannerlist.map((item, i)=>
      item.imagetype=='poster'?
          <Col>
             <Card className='cardheightst m-1' style={{border: "none"}}>
             {console.log("imagebanner",item.imagetype)}
             {/* <a href={"/"+item.shopName}> */}
             <Link to ={`/${item.shopName}/${item.product_id}`}
          state={{ itemData: item }}>
            <div className='bannerAds_1height ' >
            <Card.Img className="bannerAds_1img my-1 p-1" variant="top" src={`/${item.product_img}`} />
            {/* <Card.Img className="imagehst my-1 p-1" variant="top" src={item.shop_img} */}
            </div> 
            </Link>
            {/* </a> */}
            <Card.Text className='bannerAds_1text' >  
            <h6>{item.shop_name?item.shop_name.toUpperCase():null}</h6>
          <p>{item.product_desc}</p>
        </Card.Text>
       
             
              
            </Card> 
            


          </Col>:null
          
        )}
        </Slider>
        </div>
      </Row>
      
      </Container>
  
  
  </>
      )
  }
