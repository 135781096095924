import { Link,NavLink } from "react-router-dom"
import Container from 'react-bootstrap/Container';
export default function Navbar()
{

    return(
        <Container fluid='md' className='my-3 bg-light'>
<div className="footerLink" style={{ fontSize: '10px' }}>

<NavLink to = "/aboutus" target="_blank" className="linkfoot" >About Us</NavLink>
<NavLink to = "/contactus" target="_blank" className="linkfoot">Contact Us</NavLink>
<NavLink to = "/privacypolicy" target="_blank" className="linkfoot" >Privacy Policy</NavLink>
<NavLink to = "/dmca" target="_blank" className="linkfoot" >DMCA</NavLink>

</div>
</Container>
)
}