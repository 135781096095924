
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { NavLink,useNavigate } from "react-router-dom";
import AddC from '../AddC';
import axios from 'axios';
import MobileOtp from './MobileOtp';
export default function Resetpassword()
{

  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";

  const [getMobile,setMobile]=useState()
  const [getPin,setPin]=useState() 
  const navigate= useNavigate()
  const [getstatus, setstatus]=useState()
  const [getuserEmail,setuserEmail]=useState('') 
  const [isotprequired, setrequiredotp]=useState(true)
  const [geterrormsg,seterrormsg]=useState('')
  const [getduplicaterecerror, setduplicaterecerror]=useState(false);
  
  const setPassword=()=>
    {
        navigate('/otp')
    }
    function cancel()
    {
      navigate('/')
    }
    function resetPin()
       {
        
       const  formData= new FormData();
       
       formData.append('getuserEmail',getuserEmail)
       formData.append('getPin',getPin)

       let data={getuserEmail,getPin}
      
  // console.log("reactui",formData)
    axios.post(window.siteUrl+'/setpin',data)
    //axios.post('http://localhost:4500/setpin',data)
   .then(res=>
     {
       setstatus(res.status)
      // console.log("setpinstatus",res)
       if(res.data.affectedRows==1)
       {
        setrequiredotp(false)
       }else{
        seterrormsg(res.errormsg)
        setduplicaterecerror(true)
       }
       
       if(res.data.errormsg)
       {
        console.log("errorm",res.data.errormsg)
        seterrormsg(res.data.errormsg)
        setduplicaterecerror(true)
       }
     }, 
   res1=>console.log("pin details",res1))
   .catch(err=>console.log(err))
  //  navigate('/otp')
   
  //  setTimeout(() => {
     
  //    setshow(false)
     
  //  }, 4000)
   }

   useEffect(()=>{
    if (getduplicaterecerror==true)
    {
      setTimeout(() => {

        setduplicaterecerror(false)
      },2000)
    }
  },[getduplicaterecerror])
    return(
      <>
{isotprequired?
<>
<form className='container formC col-sm-4 col-xs-4 '>
<div className="form-group d-flex justify-content-center ">
<div className="col-sm-8 col-xs-4 align-self-center textformat">



{getduplicaterecerror?
<div>
<label for="floatingInput" className='text-danger fw-bold'>{geterrormsg}</label>
</div>
:
null
}
<label for="floatingInput" className='loginLb'>Reset Pin</label>

{/* <input maxLength={10} onChange={(e)=>setMobile(e.target.value)}type="tel" placeholder="Enter Mobile number" 
className="form-control m-3 text-center fs-6 mobileNumber" id="typePhone" name="phone" ></input> */}

<input onChange={(e)=>setuserEmail(e.target.value)}type="email" placeholder="Enter Your Email-ID" 
className="form-control m-3 text-center fs-5 userEmailid" id="typeEmail" name="email" ></input>

<input maxLength={4} onChange={(e)=>setPin(e.target.value)}type="text" placeholder ="Set 4 digit Pin" 
className="form-control m-3 text-center fs-6 pinNumber"id="pin">  
</input>
<Button onClick={resetPin}variant="primary">Set Pin</Button>{' '}   
<Button onClick={cancel}variant="danger">Cancel</Button>{' '} 
</div>
</div>
{/*  */}
</form>
</>
:
<MobileOtp email={getuserEmail}/>
}
</>
    )
}