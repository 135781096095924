import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import shoping1 from './1.jpg'
import { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Link } from "react-router-dom";
import Toastnot from './Toastnot';
import ProductDetails from './ProductDetails';

export default function PosterNearByArea(props)
{
  
  //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";
console.log("nearPosterlist",props.fulldata)
  const [getshoplist,setshoplist]=useState('');

  // useEffect(()=>
  // {

  //   let pin=null
  //   if(localStorage.getItem('user-info'))
  //   {
  //    const pinjson=localStorage.getItem('user-info')
  //      pin=JSON.parse(pinjson).user_areaCode
       
  //   }
  //   //console.log("pinid",pin)
  //   //fetch(`//allstoresprice.com:3443/getstoreimglist/${pin}`,
  //   fetch(`${window.siteUrl}/getstoreimglist/${pin}`,).then((result)=>
  
  // {
  //   result.json().then((resp)=>{
  //     //console.log("shop liststore",resp)
  //     setshoplist(resp)
  //    //  setcount(getplist.length) 
  //        //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
         
  //         // localStorage.setItem('shop-name',JSON.stringify(resp))
         
  //   })
    
  // }
  // )
  // },[])
 
  
  useEffect(()=>
  {
    const shuffledList = props.fulldata.sort(() => Math.random() - 0.5);

    setshoplist( shuffledList)
  })

    const desk=4
  const mobile=2
      // var settings = {
      //     dots: true,
      //     infinite: true,
      //     speed: 300,
      //     slidesToShow: 4,
      //     slidesToScroll: 4,
      //     autoplay: true,
      //     autoplaySpeed: 3000,
          
  
      //   };
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll:10,
        initialSlide: 0,
        rows:  3,
        // rows:Math.min(Math.floor(getshoplist.length / 15), 3),
        autoplay: true,
         autoplaySpeed: 4000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 10,
              slidesToScroll: 10,
              infinite: false,
              dots: false,
              rows:  3,
              // rows: Math.min(Math.floor(getshoplist.length / 12), 3),
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              initialSlide: 4,
              infinite: false,
              dots: false,
              rows:  3,
              // rows: Math.min(Math.floor(getshoplist.length / 4), 3),
            }
          },
          {
            breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll:2,
        infinite: false,
        dots: false,
        rows:  3,
              // rows: Math.min(Math.floor(getshoplist.length / 2), 3) ,
            }
          }
        ]
      };
      return(
  <>
  <Container fluid  className='my-1 p-1 ' >
  
  {props.city?
   <div className='text-start text-success fw-bold'>SHOPS IN {props.city.toUpperCase()}</div>
   :null}
  <Row xs={1} md={1} >
  <div className='text-white sliderim'>
     <Slider {...settings}>
        {getshoplist &&
      getshoplist.length > 0 &&
      getshoplist.map((item, i)=>
      // item.imagetype=='poster'?
          <Col>
         {console.log("poster1",item.imagetype)}
             <Card className='cardheightst m-1' style={{border: "none"}}>
             
             {/* <a href={"/"+item.shopName} > */}
             <Link to ={`/${item.shopName}/${item.product_id}`}
          state={{ itemData: item }}>
            <div className='smallcardheight m-1' >
            <Card.Img className="smallcardimg my-1 p-1" variant="top" src={`/${item.product_img}`}  />
            {/* <Card.Img className="imagehst my-1 p-1" variant="top" src={item.shop_img} */}
            </div> 
            </Link>
            {/* </a> */}
            <Card.Text className='smallcardtext ' >  
         {item.shop_name?item.shop_name.toUpperCase():null}
        </Card.Text>
            </Card> 



          </Col>
          // :null
          
        )}
        </Slider>
        </div>
      </Row>
      
      </Container>
  
  
  </>
      )
  }
