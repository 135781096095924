import Card from 'react-bootstrap/Card';
import CardImg from 'react-bootstrap/CardImg';
import CardBody from 'react-bootstrap/CardBody';
import CardTitle from 'react-bootstrap/CardTitle';
import CardText from 'react-bootstrap/CardText';
import Image from 'react-bootstrap/Image';
import shoping1 from './1.jpg'
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Holding from './Holding';
import SearchBox from './SearchBox';
import AddProduct from './AddProduct';
import { BrowserRouter, Outlet, Route, Routes, json } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { NavLink,useNavigate,useParams ,Link} from "react-router-dom";
import { ImCross  } from "react-icons/im";
import Header from './Header';
import { useState,useEffect } from 'react';
import AddC from './AddC';
import ShopBaner from './ShopBaner';
import ReactPaginate from 'react-paginate';
import ToolTip from './ToolTip';
import CloseButton from 'react-bootstrap/CloseButton';
import { Alert } from 'react-bootstrap';
import PropVal from './PropVal';
import OrderPlaced from './OrderPlaced';
import StoreImg from './StoreImg';
import FooterAdd from './FooterAdd';
import Modal from 'react-bootstrap/Modal';
import VisibilitySensor from 'react-visibility-sensor';
import { MdOutlineVisibility } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import Updateproduct from './Updateproduct';
import { AiOutlineLike } from "react-icons/ai";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Likeview from './Likeview';
import SlidingCards from './SlidingCards';
import DownProject from './DownProduct';
import SmallCardGroup from './SmallCardgroup';
import BannerAds from './BannerAds';
import ShopBannerAds from './ShopBannerAds';
import ProductDetails from './ProductDetails';
import 'typeface-sofia';

import { useLocation } from 'react-router-dom';

export default function ShopHomePage()

{

 // const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";
  const location = useLocation();
  const showAddProduct = location.state?.showAddProduct;
 
  const params= useParams()
  const {name}=params
  console.log("paremeter",params)
   const [show,setShow]=useState(false)
   const handleShow = () => setShow(true);
   const [page, setPage] = useState(1)
  const [data, setData] = useState([]);
  const [getAdd, setAdd] = useState([]);
  const [perPage] = useState(30);
  let [pageCount,getpageCount]=useState('');
  const [fullData, setFullData] = useState([]);
  const [getproductcount,setproductcount] = useState(0)
  const [getmessage,setmessage] = useState("Record added successfully")
    const navigate=useNavigate();
    const [getShowHide, setShowHide]=useState(false)
    const [getlocaluser] = useState(localStorage.getItem('user-info'));
    const [getlocalshop] = useState(localStorage.getItem('shop-name'));
    let [getShopName,setShopName]=useState('');
    let [surllist] = useState([]);
    // let [visible] = useState(false);
    const [loginusernumber, setloginusernumber]=useState('')
    const [loginusername, setloginusername]=useState('')
    const [logincusid, setlogincusid]=useState('')
    const [loginusercity, setloginusercity]=useState('')
    const [loginuseraddress, setloginuseraddress]=useState('')
    const [getcity,setcity]=useState('')
    const [getmobilenumber,setmobilenumber]=useState('')
    const [getaddress,setaddress]=useState('')
    const [getshowdetails,setshowdetails]=useState('')
    const [getshopdetails,setshopdetails]=useState('')
    const [visible,setVisible]=useState(false)
    const [visibleTime,setVisibleTime]=useState(0)
    let [visibilitystatus]=useState(false)


    
     const showProductDetails=()=>
      {
        
        setShowHide(current=>!current)
    }

    // const fetchProduct=async()=> 
    // {
    //   let result1 = await fetch("http://localhost:4500/getproducts/")
    //   result1 =  await result1.json()
    //   console.log("result",result1)
    //   setFullData(result1)
    //     const data = result1.slice(0,30);
    //     getpageCount(Math.ceil(result1.length / perPage));
  
    //   console.log("page",Math.ceil(result1.length / perPage))
    //   setData(data);
    //      console.log("hlist",result1)
    // }
    const getShopProduct=()=>{
//fetchProduct()

fetch(`${window.siteUrl}/getproducts/${name}`,
//fetch(`//allstoresprice.com:3443/getproducts/${name}`,
{
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth')
}
}).then((result)=>
//).then((result)=>
//fetch("//allstoresprice.com:3443/amleftcopy").then((result)=>
{
//console.log("resul",result)
result.json().then((resp)=>{
 setFullData(resp)
 //console.log("resuldata",resp)
 const data = resp.slice(0,30);
 getpageCount(Math.ceil(resp.length / perPage));
 setproductcount(resp.length)
//console.log("page",Math.ceil(resp.length / perPage))
setData(data);
 console.log("hlist2",resp)
  //console.log("localuser",name,getlocalshop)
  try{
  setcity(resp[0].city)
  setmobilenumber(resp[0].user_number)
  setaddress(resp[0].address)
}catch{

}
  if(resp.length>0)
  {
  setShopName(resp[0].shop_name) 
  }
   else{
    setShopName("No product available")
   }
 //  setcount(getplist.length)
   // localStorage.setItem('product-info',JSON.stringify(resp))
})
}
)
    }
    useEffect (()=>{

            if(localStorage.getItem('user-info'))
      {
        const loc=localStorage.getItem('user-info')
        const parseVal=JSON.parse(loc)
        setloginusernumber(parseVal.user_number)
        setloginusername(parseVal.user_name)
        setlogincusid(parseVal.cus_id)
        setloginusercity(parseVal.City)
        setloginuseraddress(parseVal.address)
        let shopnamedetails=localStorage.getItem('shop-name')
        
        console.log("loc",loc)
        console.log("name",name)

        //console.log("cus",parseVal.address,parseVal.City,parseVal.user_number,parseVal.user_name)
        // let shopname1=shopnamedetails
       let shopname1= JSON.parse(shopnamedetails)
       if(shopname1.length>0)
       {
       setshowdetails(shopname1[0].showDetails)
      }
        //console.log("rullist",shopname1)
   let len=shopname1.length;
   let showShopdetails;
   let showShopname

   for (let i=0;i<=len-1;i++)
   {
    showShopdetails=shopname1[i].shop_url
    if(!surllist.includes(showShopdetails))
    {
    surllist.push(showShopdetails.replaceAll('"', ''))
    
    }
    //console.log("pushurl",surllist)

  }
      }
      getShopProduct()
   },[])

   
function getaddproduct()
{
  getShopProduct()
}


   const handlePageClick = ({ selected }) => {
    const pagesVisited = selected * perPage;
    const lastSetData = pagesVisited + perPage;
    setData(fullData.slice(pagesVisited, lastSetData));
    window.scrollTo(0, 0);
   }

   
    
    
    
    let [getdelproduct,setdelproduct]=useState('')

   function showdelwindow(id)
{
  handleShowaccount()
  let filepath= id.path
  let filesplit= filepath.split("/")
  let filename = filesplit[3]
  // let filename = filepath.replace(/images\//g, "");
  // let filename = filepath.substring(7)
  let idname =id.id
    let delproduct=JSON.stringify({idname,filename})
     console.log("path", delproduct)
     setdelproduct(delproduct)
}
   
   function deleteAction()
   {
     
console.log("delete data", getdelproduct)


//fetch(`//allstoresprice.com:3443/getproducts/${data}`,
fetch(`${window.siteUrl}/getproducts/${getdelproduct}`,
{
 method:'DELETE',
 
  headers: {
  authorization:'bearar'+' '+localStorage.getItem('auth'),
  mobnumber:loginusernumber,
  shopname:name
}
}
 
).then((result)=>
{
  result.json().then((resp)=>
  {
    //console.log("shopproductlist"+resp)
    getShopProduct()
  })
})
handlecloseaccount()
   }


useEffect(()=>
{
  fetch(`${window.siteUrl}/getshopdata/${name}`,
  //fetch(`//allstoresprice.com:3443/getshoplist/${usernumber}`,
  {
    headers: {
    authorization:'bearar'+' '+localStorage.getItem('auth')
  }
}).then((result)=>
 {
   result.json().then((resp)=>{
     console.log("shopdetails",resp)
    //  setcount(getplist.length) 
        //  localStorage.setItem('user-info',JSON.stringify(resp[0]))
        setshopdetails(resp)
        
           
   }) 
 }
 )
},[])

const [showdelacc, showdelaccount] = useState(false);
const handleShowaccount = () => showdelaccount(true);
const handlecloseaccount = () => showdelaccount(false);


// function updatelikeview(id)
// {
  
//   fetch(`${siteUrl}/getlikeview/${id}`,
// {
//  method:'POST',
 
//   headers: {
//   authorization:'bearar'+' '+localStorage.getItem('auth')
// }
// }
 
// ).then((result)=>
// {
//   result.json().then((resp)=>
//   {
    
    
//   })
// })
// }


// const onChange = (isVisible, id) => {
//   visibilitystatus = isVisible
//   console.log("visibility", visibilitystatus)

//   if (isVisible) {
//     const element = document.getElementById('view')
//     if (element) {
//       const rect = element.getBoundingClientRect()
//       if (rect.top >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth) {
//         setVisible(true)
//         let startTime = new Date().getTime()
//         const timer = setInterval(() => {
//           const currentTime = new Date().getTime()
//           const timeDiff = currentTime - startTime

//           // Check if the element is still visible
//           const elementStillVisible = element.getBoundingClientRect().top >= 0 && element.getBoundingClientRect().left >= 0 && element.getBoundingClientRect().bottom <= window.innerHeight && element.getBoundingClientRect().right <= window.innerWidth

//           if (elementStillVisible) {
//             setVisibleTime(timeDiff)
//             if (timeDiff > 2000 && visibilitystatus) {
//               console.log("visibility", visibilitystatus, id, timeDiff)
//               updatelikeview(id)
//               clearInterval(timer)
//             }
//           } else {
//             // Element became invisible, reset timer and startTime
//             clearInterval(timer)
//             startTime = null
//           }
//         }, 1000)
//       }
//     }
//   } else {
//     setVisible(false)
//     setVisibleTime(0)
//   }
// }


    return(
       <div style={{backgroundColor: "#f1f2eb"}}>

{/* <AddC/> */}
    <Header/>
    <label for="floatingInput"className='loginLb text-success fw-bold shopNameheading' style={{ fontSize: '25px' }} >{getShopName?getShopName.toUpperCase():null}</label> 
    {/* <ShopBaner/>  */}
    {/* <SearchBox/> */}
    {console.log("fulldata",data)}
    {/* <BannerAds fulldata={setAdd} /> */}
    <ShopBannerAds fulldata={data}/>
    
    
    <Container fluid='md' className='my-3'>
      <Row>
        <Col xs={7}sm={8}>
       
        </Col>
        <Col xs={5}sm={4}>
        {console.log("showAddProduct",showAddProduct)}
        {
  (getlocaluser && surllist.includes(name)) || showAddProduct ? (
    <Button onClick={showProductDetails} variant="primary">
      Add Product
    </Button>
  ) : null
}
        
       </Col>
       
      </Row>
      <Row>
        <Col xs={12}sm={9}>
        {
getShowHide && <AddProduct addproduct={getaddproduct}/>
        }
        
       </Col>
       </Row>
    </Container> 
    

    {/* <StoreImg city={loginusercity}/> */}
        
    {/* <ShopBaner/> */}
      
    <Container fluid className='my-2 p-2 contheight' style={{backgroundColor: "#f1f2eb"}}>
    
     {/* <label for="floatingInput"className='loginLb text-success fw-bold' >{getShopName?getShopName.toUpperCase():null}</label>  */}
        
      {/* loginusercity?  */}
      {/* {loginusercity?
      <span className='text-success d-flex justify-content-start'><b>{loginusercity.toUpperCase()}</b></span>
      :
      null
} */}
          {
      getshopdetails!=""&& getshopdetails[0].City?
  <span className='text-success d-flex justify-content-start'><b>{getshopdetails[0].City.toUpperCase()}</b></span>
  :
  <span className='text-success d-flex justify-content-start'><b>{getcity.toUpperCase()}</b></span>
}
{getshopdetails && getshopdetails!=""?
<span className='d-flex justify-content-start text-dark ' >Mobile - <span style={{ color:'Tomato',fontSize: '20px',fontFamily: 'Sofia, sans-serif', textShadow: '2px 2px 3px #ababab', letterSpacing: '1px'}}>{getshopdetails[0].user_number}</span></span>
:
null
}
{
  getshopdetails!="" && getshopdetails[0].address?  
<span className='d-flex justify-content-start text-dark ' style={{ fontSize: '18px' }}>Address - {getshopdetails[0].address} </span>
:
null
}
{
  localStorage.getItem('user-info')?

  getshopdetails!="" &&  getshopdetails[0].address?  
null
:
<span className='d-flex justify-content-start text-danger' style={{ fontSize: '11px' }}>Update Mob. Number And Address In Profile </span>
:null
}

{/* <SlidingCards/> */}

{/* <SmallCardGroup fulldata={data}/> */}
<Outlet/>
{/* <ProductDetails sellerid={item.cus_id}productid={item.product_id}productimg={item.product_img}prodDes={item.product_desc}userareacode={item.user_areaCode}currentdata={item.Date}shopname={item.shop_name} prodname={item.product_name}prodprice={item.final_price}username={item.user_name}  address={item.address}ordercomment={item.order_comment}shopmobilenumber={item.user_number} city={item.city} className="m-2"/>  */}

<ReactPaginate
    previousLabel={'prev'}
    nextLabel={'next'}
    breakLabel={'...'}
    breakClassName={'break-me'}
    pageCount={pageCount}
    onPageChange={handlePageClick}
    containerClassName={'pagination'}
    subContainerClassName={'pages pagination'}
    activeClassName={'active'}
    
  />
  
<Row xs={2} md={5} className="g-1">
      {/* {Array.from({ length: 40 }).map((_, idx) => ( */}
      {//getplist.map ((item)=>
      data &&
      data.length > 0 &&
      data.map((item, i)=>
      item.imagetype=='product'?
        <Col>
       
          <Card className='cardheight border border-dark'>
          <Row>
          <Col xs={6}sm={6} >
          {getlocaluser?surllist.includes(name)? 
          //float-start
          <div >
             {/* <CloseButton className='text-danger' onClick={()=>showdelwindow({"id":item.id,"path":item.product_img})} ></CloseButton> */}

             <MdClose style={{cursor:"pointer"}} className='text-danger '  onClick={()=>showdelwindow({"id":item.id,"path":item.product_img})} />
             </div>
             :null
             :null
      }
          </Col>
          <Col xs={6}sm={6}>
          {getlocaluser?surllist.includes(name)?
          //float-end
             <div>
             <Updateproduct discount ={item.discount} finalprice={item.final_price}sellerid={item.cus_id}productid={item.product_id}productimg={item.product_img}prodDes={item.product_desc}userareacode={item.user_areaCode}currentdata={item.Date}shopname={item.shop_name} prodname={item.product_name}prodprice={item.product_price}usermobilenumber={loginusernumber}username={item.user_name} cusname={loginusername}useraddress={loginuseraddress}usercity={loginusercity} buyerid={logincusid} address={item.address}ordercomment={item.order_comment}shopmobilenumber={item.user_number} city={item.city} className="m-2"/>
             </div>
             :null
             :null
      }
          </Col>
        </Row>
          
             {/* <ImCross className='cross'/> */}
          <Card.Title className='my-2 cardtitle' style={{ fontSize: '14px' }}><ToolTip shopname={item.shop_name} mobileN={item.user_number} address={item.address} prodDes={item.product_desc} prodName={item.product_name} prodID={item.product_id} city={item.city}/><div className="vr" />{item.shop_name?item.shop_name.toUpperCase():null} </Card.Title>
          <div className='imageheight '>
          <Link to ={`/${name}/${item.product_id}`}
          state={{ itemData: item }}>
           
             <Card.Img className=' imageh 'variant="top" src={`/${item.product_img}`} /> 
             </Link>
            {/* <Card.Img className=' imageh'variant="top" src="images/1705214695966_deepak.jpg" /> */}
            </div>
            <Card.Body className=' p-0 my-3'>
            <Card.Text className='cardtext  ' style={{ padding: '0 !important' }}>
            <span className=' beforeprice '>₹{item.product_price}</span>
              <span className='text-danger '>({item.discount}%)</span>
              <span className='text-danger afterdiscount m-1'>₹{item.final_price}</span>
               <p>{item.product_name} ({item.product_desc})</p>
              </Card.Text>
              
              {/* onChange={()=>onChange({"id":item.id}) }*/}
              {/* {
                console.log("count",item.id,item.viewcount,item.cus_id)
              } */}
              {/* <Row>
            <Col xs={6}sm={6} >
              <Likeview id={item.id} view={item.viewcount} cusid={item.cus_id} clickcount={item.clickCount}/>
              </Col>
              <Col xs={6}sm={6}>
              <div>
              {!surllist.includes(name)?
               <>
              
               
               <OrderPlaced sellerid={item.cus_id}productid={item.product_id}productimg={item.product_img}prodDes={item.product_desc}userareacode={item.user_areaCode}currentdata={item.Date}shopname={item.shop_name} prodname={item.product_name}prodprice={item.product_price}usermobilenumber={loginusernumber}username={item.user_name} cusname={loginusername}useraddress={loginuseraddress}usercity={loginusercity} buyerid={logincusid} address={item.address}ordercomment={item.order_comment}shopmobilenumber={item.user_number} city={item.city}  className="m-2"/>
               </>
               :null
               }
               </div>
               </Col>
               </Row> */}
               
              </Card.Body>
              
    
    
          </Card>
         </Col>:
        <Col>
       
        <Card className='cardheight border border-primary'>
        <Row>
        <Col xs={6}sm={6} >
        {getlocaluser?surllist.includes(name)? 
        //float-start
        <div >
           {/* <CloseButton className='text-danger' onClick={()=>showdelwindow({"id":item.id,"path":item.product_img})} ></CloseButton> */}

           <MdClose style={{cursor:"pointer"}} className='text-danger '  onClick={()=>showdelwindow({"id":item.id,"path":item.product_img})} />
           </div>
           :null
           :null
    }
        </Col>
        {/* <Col xs={6}sm={6}>
        {getlocaluser?surllist.includes(name)?
        //float-end
           <div>
           <Updateproduct discount ={item.discount} finalprice={item.final_price}sellerid={item.cus_id}productid={item.product_id}productimg={item.product_img}prodDes={item.product_desc}userareacode={item.user_areaCode}currentdata={item.Date}shopname={item.shop_name} prodname={item.product_name}prodprice={item.product_price}usermobilenumber={loginusernumber}username={item.user_name} cusname={loginusername}useraddress={loginuseraddress}usercity={loginusercity} buyerid={logincusid} address={item.address}ordercomment={item.order_comment}shopmobilenumber={item.user_number} city={item.city} className="m-2"/>
           </div>
           :null
           :null
    }
        </Col> */}
      </Row>
        
           {/* <ImCross className='cross'/> */}
        <Card.Title className='my-2 cardtitle' style={{ fontSize: '14px' }}><ToolTip shopname={item.shop_name} mobileN={item.user_number} address={item.address} prodDes={item.product_desc} prodName={item.product_name} prodID={item.product_id} city={item.city}/><div className="vr" />{item.shop_name?item.shop_name.toUpperCase():null} </Card.Title>
        
        <div className='imageheight '>
        <Link to ={`/${name}/${item.product_id}`}
          state={{ itemData: item }}>
           <Card.Img className=' imageh 'variant="top" src={`/${item.product_img}`} /> 
           </Link>
          {/* <Card.Img className=' imageh'variant="top" src="images/1705214695966_deepak.jpg" /> */}
          </div>
          <Card.Body className=' p-0 my-3'>
          <Card.Text className='cardtext' style={{ padding: '0 !important' }}>
          {/* <span className=' beforeprice '>₹{item.product_price}</span>
            <span className='text-danger '>({item.discount}%)</span>
            <span className='text-danger afterdiscount m-1'>₹{item.final_price}</span> */}
             <p className='carddescription' > {item.product_desc}</p>
            </Card.Text>
            
            {/* onChange={()=>onChange({"id":item.id}) }*/}
            {/* {
              console.log("count",item.id,item.viewcount,item.cus_id)
            } */}
            {/* <Row>
          <Col xs={6}sm={6} >
            <Likeview id={item.id} view={item.viewcount} cusid={item.cus_id} clickcount={item.clickCount}/>
            </Col>
            <Col xs={6}sm={6}>
            <div>
            {!surllist.includes(name)?
             <>
            
             
             <OrderPlaced sellerid={item.cus_id}productid={item.product_id}productimg={item.product_img}prodDes={item.product_desc}userareacode={item.user_areaCode}currentdata={item.Date}shopname={item.shop_name} prodname={item.product_name}prodprice={item.product_price}usermobilenumber={loginusernumber}username={item.user_name} cusname={loginusername}useraddress={loginuseraddress}usercity={loginusercity} buyerid={logincusid} address={item.address}ordercomment={item.order_comment}shopmobilenumber={item.user_number} city={item.city}  className="m-2"/>
             </>
             :null
             }
             </div>
             </Col>
             </Row> */}
             
            </Card.Body>
            
  
  
        </Card>
       </Col>
      )}
      
    </Row>
    
    <Modal show={showdelacc} onHide={handlecloseaccount}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this product?
            
        </Modal.Body>
        <Modal.Footer>
         <Button variant="danger" onClick={deleteAction}>
            Delete
          </Button> 
          <Button variant="secondary" onClick={handlecloseaccount}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    {/* <FooterAdd/> */}
        </div>
        
    )
}