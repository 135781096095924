import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { Tooltip } from 'react-tooltip'
import { Button, ButtonToolbar,OverlayTrigger  } from "react-bootstrap";
import { useState, useRef } from 'react';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';

export default function ToolTip(props)
{
    
return(

<>
<OverlayTrigger
          
          key="right"
          placement="bottom"
           trigger="click"
          overlay={
            <Popover id={`popover-positioned-right`}>
              <Popover.Header as="h3">
                {/* {`${props.shopname}`} */}
              <div>
               <label for="floatingInput" className='text-primary fw-bold text-center d-flex justify-content-center p-1 m-1'>{`${props.shopname}`.toUpperCase()}</label>
               </div>
               {/* <div>
               Mobile No. -<label for="floatingInput" className='text-danger'>{`${props.mobileN}`}</label>
                </div> */}
                {/* <div>
                <label for="floatingInput" content="Address -" className='text-dark'>{`Address- ${props.address}`}</label>
                </div> */}
                <div>
                <label for="floatingInput" className='text-dark  text-center d-flex justify-content-center'>{`${props.city?props.city.toUpperCase():null}`}</label>
                </div>
              </Popover.Header>
              <Popover.Body>
              

              <div>
                <label for="floatingInput" className='text-dark'>{`Product Name - ${props.prodName}`}</label>
                </div>
                <div>
                <label for="floatingInput" className='text-dark'>{`Product ID - ${props.prodID}`}</label>
                </div>
              <div>
                <label for="floatingInput" className='text-muted'>{`Product Description - ${props.prodDes}`}</label>
                </div>
                
              </Popover.Body>
            </Popover>
          }
        >
           
          <label className="text-danger fw-bold"> <HiOutlineQuestionMarkCircle/></label> 
        </OverlayTrigger>
    </>

)
}