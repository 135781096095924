import { useEffect, useState } from 'react';
import {useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { useLocation,useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';

export default function ProductDetails(props) {
     const location = useLocation();
     const [itemData, setItemData] = useState(location.state?.itemData);
     const params = useParams();
     const { product_id } = params;
     const [isLoading, setIsLoading] = useState(true);
   
     useEffect(() => {
       if (!itemData) {
         fetch(`${window.siteUrl}/getproductdetails/${product_id}`, {
           headers: {
             authorization: 'Bearer ' + localStorage.getItem('auth'),
           },
         })
         .then((result) => result.json())
         .then((resp) => {
           setItemData(resp[0]);
           setIsLoading(false);
         })
         .catch((error) => console.error('Fetch error:', error));
       } else {
         setIsLoading(false);
       }
     }, [product_id, itemData]);
   
     if (isLoading) {
       return (
         <Container fluid="md" className="my-3 productDetails">
           <Row>
             <Col xs={12}>
               <p>Loading...</p>
             </Col>
           </Row>
         </Container>
       );
     }
   
     return (
       <Container fluid="md" className="my-3 productDetails">
         <Row>
           <Col xs={0} sm={3} />
           <Col xs={12} sm={6}>
             <div>
               <Card.Img
                 className="imagehorder"
                 variant="top"
                 src={`/${itemData.product_img}`}
                 roundedCircle
               />
             </div>
             {itemData.imagetype === 'product' ? (
               <div>
                 <InputGroup className="my-2 border border-dark">
                   <InputGroup.Text className="fw-bold text-success">
                     Product Name
                   </InputGroup.Text>
                   <label
                     htmlFor="name"
                     className="form-control my-1"
                     id="shopName"
                   >
                     {itemData.product_name}
                   </label>
                 </InputGroup>
               </div>
             ) : null}
             <div>
               <InputGroup className="my-2 border border-dark">
                 <InputGroup.Text className="fw-bold text-success">
                   Description
                 </InputGroup.Text>
                 <label
                   htmlFor="name"
                   className="form-control my-1"
                   id="shopName"
                 >
                   {`${itemData.product_desc}`}
                 </label>
               </InputGroup>
             </div>
             {itemData.imagetype === 'product' ? (
               <div>
                 <InputGroup className="my-2 border border-dark">
                   <InputGroup.Text className="fw-bold text-success">
                     Price
                   </InputGroup.Text>
                   <label
                     htmlFor="name"
                     className="form-control my-1"
                     id="price"
                   >
                     {`₹ ${itemData.product_price}`}{' '}
                     <span className="text-danger m-1">
                       ({itemData.discount}%)
                     </span>
                     <span className="text-primary afterdiscount m-1">
                       ₹{itemData.final_price}
                     </span>
                   </label>
                 </InputGroup>
               </div>
             ) : null}
           </Col>
           <Col xs={0} sm={3} />
         </Row>
       </Container>
     );
   }
   