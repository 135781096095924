import { Row } from "react-bootstrap";
import Header from './Header'
import Col from 'react-bootstrap/Col';
import { useEffect, useState,useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AddC from './AddC';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { NavLink,useNavigate } from "react-router-dom";
export default function Feedback()
{
   //const siteUrl="http://localhost:4500";
  //const siteUrl="//allstoresprice.com:3443";
  
    const [getSubject, setSubject]=useState('Feedback')
    const [getBody, setBody]= useState('')
    const [getlength, setlength]=useState('');
    let [ismailsent,setuserEmail]=useState(false)
    const [getenable, setdisable]=useState(true)
    const [getloginemail, setloginemail]=useState('')
    const bodytext=useRef('')
    const navigate=useNavigate(0);

    async function feedbackapi()
    {
let subjectupdate=getSubject+"_"+getloginemail
const maildata={subjectupdate,getBody}

console.log("data",maildata)

try{
    let pResult= fetch(window.siteUrl+"/feedbackapi",
     //let pResult= fetch("http://85.31.232.240:8080/login",
   //let pResult= fetch("//allstoresprice.com:3443/login",
   
   //let pResult= fetch("//allstoresprice.com:3443/leftPro",
   
    {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(maildata)
   }).then((pResult) => {
     //console.log("user list", pResult)
     pResult.json().then((resp) => {
       console.log("feedbackDetails", resp)
       if(resp.result='mail sent')
       {
        setuserEmail(true)
        
        bodytext.current.value=''
       }
}
     )
   }
   )
}catch
{

}
    }
    
    
    function cancel()
    {
      navigate('/')
    }

    useEffect(()=>
    {
      if(getBody.length>=1)
      {
      setdisable(false)
    }
    })

    let userData
    useEffect(()=>
    {
      if(localStorage.getItem('user-info'))
      {
        userData=localStorage.getItem('user-info')
        const details= JSON.parse(userData);
        setloginemail(details.user_emailid)
      }
    },[])
    return(
<>
{/* <AddC/> */}
<Header/>
<form className='container formC col-sm-3  col-xs-12  '>
{
ismailsent==false?
<div className="form-group ">

<div className="col-sm-12 col-xs-12 align-self-center textformat ">


<label for="floatingInput" className="loginLb ml-2">Feedback</label>


<Form.Select aria-label="Default select example" className="p-2" onChange={(e)=>setSubject(e.target.value)}>
      <option>Feedback</option>
      <option value="Issue/Complaint">Issue/Complaint</option>
      
    </Form.Select>

    <FloatingLabel controlId="floatingTextarea2 mb-3" className="my-3" >
        <Form.Control
        id="commid"
        controlId="commentsid"
          as="textarea"
          className='border border-dark'
          placeholder="Leave a comment here" 
          onChange={(e)=>setBody(e.target.value)}
          ref={bodytext} 
		//   value={address} onChange={(e)=>setaddress(e.target.value)}
          style={{ height: '170px' }}>{``}</Form.Control>

<label for="floatingInput" className='text-danger p-3'>comments</label>
</FloatingLabel>

<Row className="justify-content-md-center">
      <Col xs={12} sm={8}>
<Button variant="primary" disabled={getenable} onClick={feedbackapi}className='btn-sm'>Submit</Button>{' '} 
<Button variant="danger" onClick={cancel}className='btn-sm'>Cancel</Button>{' '} 
</Col>
</Row>


</div>
</div>
:
<p>Mail sent successfully</p>
}
</form>
</>
    )
}